import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Media                from "Components/Chat/Media";
import Reply                from "Components/Chat/Reply";
import Html                 from "Components/Utils/Html";
import Icon                 from "Components/Utils/Icon";



// Styles
const Container = Styled.div.attrs(({ isMine, padding }) => ({ isMine, padding }))`
    --bubble-padding: ${(props) => props.padding + 12}px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;
    max-width: min(calc(680px - var(--bubble-padding)), calc(100% - var(--bubble-padding)));
    margin-bottom: 4px;

    ${(props) => props.isMine ? `
        --bubble-background: var(--bubble-mine-bg, #1f85ff);
        --bubble-color: var(--bubble-mine-color, white);

        flex-direction: row-reverse;
        padding-left: var(--bubble-padding);

        &:last-child .bubble::before {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            right: -8px;
            height: 20px;
            width: 20px;
            background: var(--bubble-background);
            border-bottom-left-radius: 15px;
        }
        &:last-child .bubble::after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            right: -10px;
            width: 10px;
            height: 21px;
            background: white;
            border-bottom-left-radius: 10px;
        }
    ` : `
        --bubble-background: var(--bubble-your-bg, #e2e3e4);
        --bubble-color: var(--bubble-your-color, var(--black-color));

        flex-direction: row;
        padding-right: var(--bubble-padding);

        &:last-child .bubble::before {
            content: "";
            position: absolute;
            z-index: 0;
            bottom: 0;
            left: -7px;
            height: 20px;
            width: 20px;
            background: var(--bubble-background);
            border-bottom-right-radius: 15px;
        }
        &:last-child .bubble::after {
            content: "";
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: -10px;
            width: 10px;
            height: 20px;
            background: white;
            border-bottom-right-radius: 10px;
        }
    `}

    &:first-child {
        margin-top: 0;
    }
    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        --bubble-padding: 12px;
    }
    &:hover .bubble-action {
        display: flex;
    }
`;

const Content = Styled.div.attrs(({ isMine }) => ({ isMine }))`
    overflow: hidden;
    ${(props) => props.isMine ? "padding-right: 12px" : "padding-left: 12px"};
`;

const Inner = Styled.div.attrs(({ withSpacing }) => ({ withSpacing }))`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 12px;
    border-radius: var(--border-radius);
    min-width: ${(props) => props.withSpacing ? "80px" : "40px"};;
    min-height: 17px;
    background-color: var(--bubble-background);
    color: var(--bubble-color);
`;

const Text = Styled(Html).attrs(({ hasIcon, withSpacing, useBigFont }) => ({ hasIcon, withSpacing, useBigFont }))`
    --text-space: ${(props) => props.hasIcon ? "48px" : "32px"};
    ${(props) => props.withSpacing && "margin-right: var(--text-space)"};
    ${(props) => props.useBigFont && "font-size: 32px;"};

    overflow: hidden;
    text-overflow: ellipsis;

    a {
        color: var(--bubble-color);
    }
`;

const Footer = Styled.footer.attrs(({ isMine, withSpacing }) => ({ isMine, withSpacing }))`
    font-size: 11px;
    color: ${(props) => props.isMine ? "rgba(255, 255, 255, 0.8)" : "rgba(0, 0, 0, 0.5)"};

    ${(props) => props.withSpacing ? `
        position: absolute;
        bottom: 4px;
        right: 8px;
    ` : `
        text-align: right;
        margin-top: -10px;
        transform: translate(2px, 4px);
    `}
`;
const StatusIcon = Styled(Icon).attrs(({ isRead }) => ({ isRead }))`
    font-size: 13px;
    margin-left: 4px;
    ${(props) => props.isRead && "color: rgba(0, 255, 0);"}
`;

const Options = Styled.ol`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2px;
    list-style: none;
    margin: 2px 0 0 0;
    padding: 0;
`;

const Option = Styled.li`
    box-sizing: border-box;
    display: block;
    padding: 8px 12px;
    border-radius: var(--border-radius);
    min-width: calc(50% - 2px);
    flex-grow: 2;
    background-color: var(--bubble-background);
    color: var(--bubble-color);
    line-height: 1;
    text-decoration: underline;
    text-align: center;
    transition: all 0.2s;
    filter: brightness(105%);
    cursor: pointer;

    &:hover {
        filter: brightness(95%);
    }
`;

const ActionIcon = Styled(Icon)`
    flex-shrink: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    font-size: 18px;
    border-radius: var(--border-radius-small);
    background-color: rgb(235, 236, 240);
    transition: all 0.2s ease-in-out;
    z-index: 1;
    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;

const Reactions = Styled.ul.attrs(({ isMine, amount }) => ({ isMine, amount }))`
    position: relative;
    top: -4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    list-style: none;
    height: 26px;
    width: ${(props) => props.amount * 20 + (props.amount - 1) * 4 + 8}px;
    min-width: 20px;
    margin: 0 auto;
    padding: 0 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
    font-size: 16px;
    background-color: rgb(235, 236, 240);
    ${(props) => props.isMine ? "margin-right: 12px;" : "margin-left: 12px;"}
`;



/**
 * The Bubble
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Bubble(props) {
    const { id, item, isMine, onReply, onReaction } = props;

    const sendOption   = Store.useOption();


    // Variables
    const text         = item.message;
    const date         = Utils.formatTime(item.createdTime);
    const hasText      = !!text;
    const icon         = item.isDelivered ? "double-check" : (item.isSent ? "check" : "scheduled");
    const hasIcon      = isMine && !!icon;
    const hasReactions = Boolean(item.reactions && item.reactions.length);
    const hasButtons   = Boolean(item.buttons && item.buttons.length);
    const files        = item.files || [];
    const reactionRef  = React.useRef(null);
    const padding      = 2 * (26 + 8);
    const useBigFont   = Boolean(!files.length && hasText && Utils.isEmojiOnly(text) && text.length <= 8);
    const withSpacing  = Boolean(!useBigFont && !item.replyToID && !files.length && text.length < 35);


    // Handles the Option
    const handleOption = (payload, message) => {
        sendOption(payload, message, item.messageID);
    };

    // Handles the Reply
    const handleReply = () => {
        if (onReply) {
            if (!text && item.file.fileText) {
                onReply(item.id, item.file.fileText);
            } else {
                onReply(item.id, text);
            }
        }
    };

    // Handles the Reaction
    const handleReaction = () => {
        if (onReaction && reactionRef.current) {
            onReaction(item.id, reactionRef.current.getBoundingClientRect());
        }
    };



    // Do the Render
    return <Container
        id={id}
        isMine={isMine}
        padding={padding}
    >
        <Content isMine={isMine}>
            <Inner className="bubble" withSpacing={withSpacing}>
                <Reply
                    item={item}
                    hasText={hasText}
                    hasIcon={hasIcon}
                />

                {files.map((file) => <Media
                    key={file.fileID}
                    media={file}
                    hasText={hasText}
                    hasIcon={hasIcon}
                />)}
                {hasText && <Text
                    hasIcon={hasIcon}
                    withSpacing={withSpacing}
                    useBigFont={useBigFont}
                    content={text}
                    addLinks
                    addBreaks
                    formatText
                />}

                <Footer isMine={isMine} withSpacing={withSpacing}>
                    <span>{date}</span>
                    {hasIcon && <StatusIcon
                        isRead={item.isRead}
                        icon={icon}
                    />}
                </Footer>
            </Inner>

            {hasReactions && <Reactions
                isMine={isMine}
                amount={item.reactions.length}
            >
                {item.reactions.map(({ id, reaction }) => <li key={id}>
                    {reaction}
                </li>)}
            </Reactions>}

            {hasButtons && <Options>
                {item.buttons.map(({ payload, message }) => <Option
                    key={payload}
                    onClick={() => handleOption(payload, message)}
                >
                    {message}
                </Option>)}
            </Options>}
        </Content>

        <ActionIcon
            className="bubble-action"
            icon="reply"
            onClick={handleReply}
        />
        <ActionIcon
            passedRef={reactionRef}
            className="bubble-action"
            icon="emoji"
            onClick={handleReaction}
        />
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Bubble.propTypes = {
    id         : PropTypes.string,
    item       : PropTypes.object,
    isMine     : PropTypes.bool,
    onReply    : PropTypes.func,
    onReaction : PropTypes.func,
};

/**
 * The Default Properties
 * @type {Object} defaultProps
 */
Bubble.defaultProps = {
    item   : {},
    isMine : false,
};

export default Bubble;
